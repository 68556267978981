<template>
  <div class='am-payPwd' :id="`ids_${id}`">
    <input type="password"
           readonly
           onfocus="this.removeAttribute('readonly');"
           maxlength="1"
           autocomplete="new-password"
           @input="changeInput"
           @click="changePwd"
           v-model="pwdList[i]"
           @keyup="keyUp($event)"
           @keydown="oldPwdList = pwdList.length"
           class="shortInput"
           v-for="(v, i) in 6" :key="i">
  </div>
</template>

<script>
export default {
  name: "password",
  data() {
    return {
      pwdList: [],
      oldPwdList: [],
      isDelete: false,
      ipt: '',
      isLock: false,
    }
  },
  props: {
    id: {
      type: Number,
      default: 1
    },
  },
  mounted() {
    this.ipt = document.querySelectorAll(`#ids_${this.id} .shortInput`)
  },
  methods: {
    keyUp(ev) {
      let index = this.pwdList.length
      if (!index) return
      if (ev.keyCode === 8) {
        this.isDelete = true
        if (this.oldPwdList === this.pwdList.length) {
          if (index === this.pwdList.length) {
            this.pwdList.pop()
          }
          index--
        } else {
          index > 0 && index--
        }
        this.ipt[index].focus()
      } else if (this.isDelete && index === this.pwdList.length && /^\d$/.test(ev.key)) {
        this.isDelete = false
        this.pwdList.pop()
        this.pwdList.push(ev.key)
        this.ipt[this.pwdList.length] && this.ipt[this.pwdList.length].focus()
      }
      this.$emit('getPwd', this.pwdList.join(''));
      if (this.pwdList.length === 6) {
        this.ipt[this.pwdList.length - 1] && this.ipt[this.pwdList.length - 1].blur();
        this.$emit('success', this.pwdList.join(''));
      }
    },
    changePwd() {
      let index = this.pwdList.length
      index === 6 && index--
      this.ipt[index].focus()
    },
    changeInput() {
      let index = this.pwdList.length
      let val = this.pwdList[index - 1]
      if (!/[0-9]/.test(val)) {
        this.pwdList.pop()
        return
      }
      if (!val) {
        this.pwdList.pop()
        index--
        if (index > 0) this.ipt[index - 1].focus()
      } else {
        if (index < 6) this.ipt[index].focus()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.am-payPwd {
  display: inline-block;
  border-radius: 5px;
  padding: 10px 0;
  border: 1px solid #cccccc;
  position: relative;
  margin-left: 1px;

  .shortInput {
    text-align: center;
    font-size: 30px;
    border-radius: unset;
    float: left;
    width: 40px;
    height: 20px !important;
    color: #333;
    outline: #ff0067;
    border: none;
    border-right: 1px solid #eee;
    background: none;

    &:last-child {
      border-right: 0;
    }
  }
}

::-ms-clear {
  display: none;
}

::-ms-reveal {
  display: none;
}
</style>
